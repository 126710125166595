/**
 * Added mouse over event to the image.
 */
function imageSwap() {
    $(".productTileImage").mouseenter(function () {
        var src = $(this).attr("data-secondary-image-src");
        if (src != null && src != "null") {
            $(this).attr("src", src);
        }
    });     

    $(".productTileImage").mouseleave(function () {
        var src = $(this).attr("data-image-src");
        $(this).attr("src", src); 
    }); 

    $(".productTileImage").bind("touchend",function () {
        var src = $(this).attr("data-secondary-image-src");
        if (src != null && src != "null") {
            $(this).attr("src", src);
        }
    });
}

module.exports = {
    imageSwap: imageSwap
};
