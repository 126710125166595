var base = require("../components/product/base");
var focusHelper = require("@sfra/js/components/focus");
var cartMessage = require("./cartMessage");
var notifyme = require("./notifyme");
var dyHelper = require("../dynamicyield/dy");
var gaHelper = require("../tracking/googleanalytics");
var timer = require("../components/timer");
var modalResponseHelper = require("./modalResponseHelper");
var peTracking = require("../tracking/pushEngageTrackingEvent");
/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($("#quickViewModal").length !== 0) {
        $("#quickViewModal").remove();
    }
    var htmlString = "<!-- Modal -->" +
        "<div class=\"modal fade\" id=\"quickViewModal\" role=\"dialog\">" +
        "<span class=\"enter-message sr-only\" ></span>" +
        "<div class=\"modal-dialog quick-view-dialog\">" +
        "<!-- Modal content-->" +
        "<div class=\"modal-content\">" +
        "<div class=\"modal-body\"></div>" +
        "</div>" +
        "</div>" +
        "</div>";
    $("body").append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".product-quickview");
    var footer = $html.find(".modal-footer").children();

    return {
        body: body,
        footer: footer
    };
}

/**
 * Get the wishlist buttons of the product and load in the quickview modal
 */
function loadWishlistContent() {
    $.ajax({
        url: $("#quickViewModal .wishlistcontent").data("wishlist-url"),
        method: "GET",
        success: function (data) {
            $("#quickViewModal .wishlistcontent").html(data);
        }
    });
}

function variationCarouselQV() {
    $(".variation-attributes-carousel-normal").owlCarousel({
        autoWidth: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        nav: true,
        slideBy: 4,
        responsive: {
            769: {
                items: 6,
            },
            1310: {
                items: 7,
            },
            1460: {
                items: 8,
            }
        },
    });
}

function variationCarouselEnhancedQV() {
    $(".variation-attributes-carousel-enhanced").owlCarousel({
        autoWidth: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        nav: true,
        slideBy: 4,
        responsive: {
            769: {
                items: 6,
            },
            1310: {
                items: 7,
            },
            1460: {
                items: 8,
            }
        },
    });
}

/**
 * Get the attribute content of the product and load in the quickview modal
 */
function loadAttributeContent() {
    $.ajax({
        url: $("#quickViewModal .attributes").data("attribute-url"),
        method: "GET",
        success: function (data) {
            $("#quickViewModal .attributes").html(data);

            var $selectedVariantsQV = $("span.selected-variant-value");
            for (var i = 0; i < $selectedVariantsQV.length; i++) {
                var selectedVariantValue = $($selectedVariantsQV[i]).parent().siblings().find("span.selected").data("attr-value");
                var selectedAttributeName = $($selectedVariantsQV[i]).parent().siblings().find("span.selected").data("attr-name");
                if (selectedAttributeName == "weight" || selectedAttributeName == "gem weight") {
                    $(".weight span.selected-variant-value").text(selectedVariantValue);
                } else {
                    $(`.${selectedAttributeName} span.selected-variant-value`).text(selectedVariantValue);
                }
            }

            variationCarouselQV();
            variationCarouselEnhancedQV();

            $(".js-attribute-hover").on("mouseover", ".swatchable", function (e) {
                e.preventDefault();
                var hoveredValue = $(this).find("[data-attr-value]").data("attr-value");
                $(".js-attribute-hover").find(".selected-variant-value").text(hoveredValue);
            });

            $(".js-attribute-hover").on("mouseleave", ".swatchable", function (e) {
                e.preventDefault();
                var selectedValue = $(this).closest(".js-attribute-hover").find("[data-attr-value].selected").data("attr-value");
                $(".js-attribute-hover").find(".selected-variant-value").text(selectedValue);
            });

            $(".attribute").on("mouseover", ".swatchable", function (e) {
                e.preventDefault();
                var hoveredVariantImage = $(this).find(".swatch-value").attr("main-image");

                if (hoveredVariantImage) {
                    var $pdpCarousel = $(this).closest(".product-quickview");
                    var $activeSlide = $pdpCarousel.find(".owl-item.active img");
                    $activeSlide.attr("src", hoveredVariantImage);
                }
            });

            $(".attribute").on("mouseleave", ".swatchable", function (e) {
                e.preventDefault();
                var hoveredVariantImage = $(this).closest(".attribute").find(".swatch-value.selected").attr("main-image");

                if (hoveredVariantImage) {
                    $(this).closest(".product-quickview")
                        .find(".owl-item.active img")
                        .attr("src", hoveredVariantImage);
                }
            });
        }
    });
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    $("#quickViewModal .modal-body").spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: "GET",
        dataType: "json",
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $("#quickViewModal .modal-body").empty();
            $("#quickViewModal .modal-body").html(parsedHtml.body);
            $("#quickViewModal .modal-footer").html(parsedHtml.footer);
            $("#quickViewModal .full-pdp-link").attr("href", data.productUrl);
            $("#quickViewModal .size-chart").attr("href", data.productUrl);
            $("#quickViewModal .modal-header .close .sr-only").text(data.closeButtonText);
            $("#quickViewModal .enter-message").text(data.enterDialogMessage);
            if ((data.product !== null && data.product !== undefined) && (data.product.shippingInfoDetails !== null)) {
                $(".shippinginfo-quickview").append(data.product.shippingInfoDetails);
            }
            if ((data.product !== null && data.product !== undefined) && (data.product.paymentIcons !== null)) {
                $(".secure-payment-options-quickview").append(data.product.paymentIcons);
            }

            $("#quickViewModal").modal("show");
            $("body").trigger("quickview:ready");
            loadWishlistContent();
            loadAttributeContent();
            var qvsku = $("div.qv-model-product-id").text();
            if (typeof TurnToCmd !== "undefined") { // eslint-disable-line
                TurnToCmd("initialize"); // eslint-disable-line
                TurnToCmd("set", {"sku": qvsku}); // eslint-disable-line
                TurnToCmd("gallery.set", {skus: [qvsku]}); // eslint-disable-line
            }

            if (data.serverTime && data.serverTime != "") {
                $(".ra-server-time").val(data.serverTime);
                $(".ra-pdp-server-time").val(data.serverTime);
            }
            timer.updateAuctionTimer();

            /* eslint-disable no-undef */
            if (yotpoWidgetsContainer) {
                yotpoWidgetsContainer.initWidgets();
            }

            /* eslint-enable no-undef */

            $.spinner().stop();
            var dyUrl = selectedValueUrl.split("?")[1];
            const params = new URLSearchParams(dyUrl);
            var dyForm = {
                pid: params.get("pid").toString()
            };
            dyHelper.fireDYEvent("SPA_QUICK_VIEW", dyForm);
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    submitNotifyMeQuickView: notifyme.notifyMeInit(),
    showQuickview: function () {
        $("body").on("click", ".quickview", function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest("a.quickview").attr("data-url");
            if (selectedValueUrl.indexOf("onair=true") !== -1) {
                const isVariantSelected = require("../liveTv/liveTvProductsDetails").isVariationSelectionOk();
                if (!isVariantSelected) {
                    if ($("#quickViewModal").length !== 0) {
                        $("#quickViewModal").remove();
                    }
                    $("#variantSelectionErrorModal").modal("show");
                    return;
                }
            }

            $(e.target).trigger("quickview:show");
            getModalHtmlElement();
            fillModalElement(selectedValueUrl);
        });

        $("body").on("keypress", ".bid-amount", function (e) {
            var char = String.fromCharCode(e.which);
            if (!(/[0-9]/.test(char))) {
                e.preventDefault();
            }
        });

        $("body").on("click", ".what-is-this", function (e) {
            e.preventDefault();
            $("#fastBuyModal").addClass("modal").modal("show");
        });

        $("body").on("click", ".modal-backdrop, .fa-times", function (e) {
            e.preventDefault();
            $("#fastBuyModal").modal("hide").removeClass("modal");
            $("#quickViewModal").addClass("modal-overflow");
        });
    },
    showExpandImages: function () {
        var processInclude = require("base/util");
        processInclude(require("./zoom"));

        $("body").on("click", ".expand-product", function () {
            $("#imageExpandModal").modal("show");
            $("#imageExpandModal").removeClass("d-none");
            $(".modal-backdrop").show();
            window.dispatchEvent(new Event("resize"));
        });
        $("body").on("click", ".video-360-container .video-icon, .video-360-container .video-label", function () {
            $(".video-360-modal").find(".modal-dialog").addClass("livetv360video");
            $(".modal-backdrop").hide();
        });
        $("body").on("click", ".imageExpandModal .close", function () {
            $("#imageExpandModal").modal("hide");
            $("#imageExpandModal").addClass("d-none");
        });

        $("body").on("click", ".show-zoom-mobile", function () {
            $("#zoomModal").modal("show");
            $("#zoomModal").removeClass("d-none");
        });
        $("body").on("click", ".zoom-modal .close", function () {
            $("#zoomModal").modal("hide");
            $("#zoomModal").addClass("d-none");
        });

        $("body").on("click", "#video360Modal, #imageExpandModal, .asset-modal.ring-size", function () {
            $(this).on("hidden.bs.modal", function () {
                $("body").addClass("modal-open");
            });
        });

        $("body").on("click", ".product-quickview .qv-close", function () {
            $("#quickViewModal").modal("hide");
        });
    },
    focusQuickview: function () {
        $("body").on("shown.bs.modal", "#quickViewModal", function () {
            $("#quickViewModal .close").focus();
        });
    },
    trapQuickviewFocus: function () {
        $("body").on("keydown", "#quickViewModal", function (e) {
            var focusParams = {
                event: e,
                containerSelector: "#quickViewModal",
                firstElementSelector: ".full-pdp-link",
                lastElementSelector: ".add-to-cart-global",
                nextToLastElementSelector: ".modal-footer .quantity-select"
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    availability: base.availability,
    colorAttribute: base.colorAttribute,
    attributeSelect: base.attributeSelect,
    selectAttribute: base.selectAttribute,

    beforeUpdateAttribute: function () {
        $("body").on("product:beforeAttributeSelect", function () {
            //$(".modal.show .modal-content").spinner().start();
        });
    },
    updateAttribute: function () {
        $("body").on("product:afterAttributeSelect", function (e, response) {
            if (response.data.product.budgetPay.hasBudgetPay && response.data.product.budgetPay.installmentsNumber) {
                response.container.find(".budget-pay-container").removeClass("d-none");
                response.container.find(".budget-pay.contentAssettooltip").text(response.data.product.budgetPay.installmentsNumber+ " x " +"$"+response.data.product.budgetPay.installmentAmount+"*").append("<i class='fa fa-info-circle contentAssettooltipPDP'></i>");
            } else {
                response.container.find(".budget-pay-container").addClass("d-none");
            }
            if ($(".modal.show .product-quickview>.bundle-items").length) {
                $(".modal.show").find(response.container).data("pid", response.data.product.id);
                $(".modal.show").find(response.container)
                    .find(".product-id").text(response.data.product.id);
            } else if ($(".set-items").length) {
                response.container.find(".product-id").text(response.data.product.id);
            } else {
                $(".modal.show .product-quickview").data("pid", response.data.product.id);
                $(".modal.show .full-pdp-link")
                    .attr("href", response.data.product.selectedProductUrl);
                $(".modal.show").find(response.container)
                    .find(".product-id").text(response.data.product.id);
                $(".modal.show").find(response.container)
                    .find(".product-name").text(response.data.product.productName);
            }
            if (response.data.product.wishlist !== null) {
                if (response.data.product.wishlist == false) {
                    response.container.find(".remove-wishlist-btn").addClass("d-none");
                    response.container.find(".qv-wishlist-add").removeClass("d-none");
                }
                else {
                    response.container.find(".qv-wishlist-add").addClass("d-none");
                    response.container.find(".remove-wishlist-btn").removeClass("d-none");
                }
            }

            if (response.data.product.price.sales.value > $(".salesprice-promotion-free-shipping .free_shipping_badge").attr("data-freeshippingqv-limit")) {
                response.container.find(".salesprice-promotion-free-shipping").addClass("freeshippingproduct");
            } else {
                response.container.find(".salesprice-promotion-free-shipping").removeClass("freeshippingproduct");
            }

            if (response.data.product.isClearance) {
                response.container.find(".clearance-price-label").removeClass("d-none");
                response.container.find(".salesprice-promotion-free-shipping").addClass("clearanceproductqv");

            } else {
                response.container.find(".clearance-price-label").addClass("d-none");
                response.container.find(".salesprice-promotion-free-shipping").removeClass("clearanceproductqv");
            }

            // Promotional callout message changes on changing of the swatches
            var objCalloutPromotion = response.data.product.calloutPromotion;

            if (objCalloutPromotion && objCalloutPromotion.pdpCallout) {
                response.container.find(".promotion-callout-message").html(objCalloutPromotion.pdpCallout);
            }
        });
    },
    updateAddToCart: function () {
        $("body").on("product:updateAddToCart", function (e, response) {
            // update local add to cart (for sets)
            $("button.add-to-cart", response.$productContainer).attr("disabled",
                (!response.product.readyToOrder || !response.product.available));

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest(".quick-view-dialog");

            // if (response.product.readyToOrder && response.product.available) {
            //     $(".main-fast-buy-wrapper, .fastbuy-wrapper").removeClass("d-none");
            // } else {
            //     $(".main-fast-buy-wrapper, .fastbuy-wrapper").addClass("d-none");
            // }

            $(".add-to-cart-global", dialog).attr("disabled", !$(".global-availability", dialog).data("ready-to-order") ||
                !$(".global-availability", dialog).data("available")
            );
        });
    },
    updateAvailability: function () {
        $("body").on("product:updateAvailability", function (e, response) {
            // bundle individual products
            $(".product-availability", response.$productContainer)
                .data("ready-to-order", response.product.readyToOrder)
                .data("available", response.product.available)
                .find(".availability-msg")
                .empty()
                .html(response.message);

            var lowStockImgUrl = (response.product.isPreOrder == null || !response.product.isPreOrder) && response.product.availability.advancedMessages.length > 0 ? $(".availability-msg").data("url"): "";

            if (response && response.product && !response.product.available) {
                $(".product-quickview").find(".global-availability .actions-row").addClass("d-none");
                $(".product-quickview").find(".global-availability .availDays ").addClass("d-none");
                $(".product-quickview").find(".global-availability .wishlistcontent").addClass("d-none");
                $(".product-quickview").find(".quantity-availdays").addClass("d-none");
                // $(".product-quickview").find(".global-availability .actions-row .fast-buy-btn-quick-view").addClass("d-none");
                // $(".product-quickview").find(".global-availability .actions-row .js-fast-buy-what-is-this").addClass("d-none");
            } else {
                $(".product-quickview").find(".global-availability .actions-row").removeClass("d-none");
                $(".product-quickview").find(".global-availability .availDays ").removeClass("d-none");
                $(".product-quickview").find(".global-availability .wishlistcontent").removeClass("d-none");
                $(".out-of-stock-label").addClass("d-none");
                $(".product-quickview").find(".quantity-availdays").removeClass("d-none");
                // $(".product-quickview").find(".global-availability .actions-row .fast-buy-btn-quick-view").removeClass("d-none");
                // $(".product-quickview").find(".global-availability .actions-row .js-fast-buy-what-is-this").removeClass("d-none");
            }

            var dialog = $(response.$productContainer)
                .closest(".quick-view-dialog");

            if ($(".product-availability", dialog).length) {
                // bundle all products
                var allAvailable = $(".product-availability", dialog).toArray()
                    .every(function (item) {
                        return $(item).data("available");
                    });

                var allReady = $(".product-availability", dialog).toArray()
                    .every(function (item) {
                        return $(item).data("ready-to-order");
                    });

                $(".global-availability", dialog)
                    .data("ready-to-order", allReady)
                    .data("available", allAvailable);

                $(".global-availability .availability-msg", dialog).empty()
                    .html(allReady ? response.message : "").find(".low-stock").append("<img width=\"8\" src=\"" + lowStockImgUrl + "\">");
            } else {
                // single product
                $(".global-availability", dialog)
                    .data("ready-to-order", response.product.readyToOrder)
                    .data("available", response.product.available)
                    .find(".availability-msg")
                    .empty()
                    .html(response.message);
            }
        });
    },
    quantitySpinner: function () {
        $(document).off("click", ".product-quickview .number-spinner button").on("click", ".product-quickview .number-spinner button", function () {
            var btn = $(this),
                oldValue = btn.closest(".number-spinner").find("input").val().trim(),
                newVal = 0;
            var maxVal = btn.closest(".number-spinner").find("input").attr("max");

            if (btn.attr("data-dir") == "up") {
                if (parseInt(oldValue, 10) < parseInt(maxVal, 10)) {
                    newVal = parseInt(oldValue, 10) + 1;
                } else {
                    btn.attr("disabled", "disabled");
                    btn.addClass("prdMax");
                    return false;
                }
            } else {
                if (oldValue > 1) {
                    newVal = parseInt(oldValue, 10) - 1;
                    $(".data-up-btn").removeAttr("disabled", "disabled");
                    $(".data-up-btn").removeClass("prdMax");
                } else {
                    newVal = 1;
                }
            }

            btn.closest(".number-spinner").find("input").val(newVal);
        });
    },

    addToCart: function () {
        $(document).on("click", ".add-to-cart-quickview", function () {
            var $this = $(this);

            var $productContainer = $this.closest(".product-detail");
            var addToCartUrl = $this.data("url");
            // On Air product tile Add to Cart modal button
            var pid = $this.closest(".product").data("pid");
            var qty = 1;

            if (!pid) {
                // PLP product tile wrapper
                pid = $this.data("pid");
            }

            if (!pid) {
                // Add to cart modal
                pid = $(".product-id").text();
            }

            if (!addToCartUrl || !pid) {
                return;
            }

            if ($productContainer.find(".attribute .size-value").length > 0 && $productContainer.find(".attribute .size-value").closest(".selected").length < 1) {
                if ($productContainer.find(".size-selection-error").length > 0) {
                    $productContainer.find(".size-selection-error").removeClass("d-none");
                    var sizeErrorElement = $productContainer.find(".price-container .js-formatted-price")[0];
                    sizeErrorElement.scrollIntoView({ behavior: "smooth" });
                }
                return;
            }

            if ($("#quickViewModal.modal.show").length > 0 && $("#quantity").length == 1)
                qty = parseInt($this.closest(".detail-panel").find("input.quantity").val(), 10);
            var form = {
                pid: pid,
                quantity: qty
            };
            var dyForm = {
                pid: pid,
                quantity: qty,
                price: $this.parents(".product-quickview").find(".sales .js-formatted-price").data("formatedprice")
            };
            var gaForm = {
                quantity: qty,
                gaprice: $this.parents(".product-quickview.product-detail").find(".js-formatted-price").text().trim().replace("$", "").replace("NOW:", "").trim()
            };
            var prodDetObjForPE = $this.closest(".product-detail").data("productdetails");
            if ((typeof prodDetObjForPE === "string" || prodDetObjForPE instanceof String) && prodDetObjForPE != "") {
                prodDetObjForPE = JSON.parse(prodDetObjForPE);
            }
            //alert expiry time current time + 30 days
            var currentdate = new Date();
            currentdate.setDate(currentdate.getDate() + 30);
            var expiryisodate = currentdate.toISOString();
            var pePDAObj = {
                productid: prodDetObjForPE ? prodDetObjForPE.id : "",
                link: prodDetObjForPE ? prodDetObjForPE.pdpurl : "",
                price: prodDetObjForPE ? prodDetObjForPE.listprice : "",
                alertprice: prodDetObjForPE ? prodDetObjForPE.salesprice : "",
                expiryisodate: expiryisodate,
                availability: "inStock",
                variantid: prodDetObjForPE ? prodDetObjForPE.id : "",
                title: prodDetObjForPE ? prodDetObjForPE.name : "",
                imageurl: $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "",
                bigimageurl: prodDetObjForPE ? prodDetObjForPE.imageurl : ""
            };
            $("body").trigger("product:beforeAddToCart", this);
            $this.trigger("updateAddToCartFormData", form);

            $.ajax({
                url: addToCartUrl,
                method: "POST",
                data: form,
                success: function success(data) {
                    cartMessage.handlePostCartAdd(data);
                    $("body").trigger("product:afterAddToCart", data);
                    $("body").trigger("fpcProduct:afterAddToCart", data);
                    $(".minicart").trigger("count:update", data);

                    gaHelper.fireGAEvent("ADD_TO_CART", gaForm, "Quick View");
                    dyHelper.fireDYEvent("ADD_TO_CART", dyForm);
                    if (pePDAObj.link !== "") {
                        peTracking.pushEngageEvent("PRICE_DROP_ALERT", pePDAObj);
                    }
                },
                error: function error(data) {
                    var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                    if (modal) {
                        $(modal.modalId).modal("show");
                    }
                }
            });
        });
    }
};

