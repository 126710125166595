
var baseProduct = require("../components/product/base");
var carousel = require("./carousel");
var zoom = require("./zoom");
var tooltip = require("./productSpecificationTooltip");
const gtmHelper = require("../../js/gtm/gtm");

function appendParamsToUrl(url, name, value) {
    if (url.indexOf("?" + name + "=") !== -1 || url.indexOf("&" + name + "=") !== -1) {
        return url;
    }
    var separator = url.indexOf("?") !== -1 ? "&" : "?";
    return url + separator + name + "=" + encodeURIComponent(value);
}

module.exports = function () {
    $(document).on("click", ".swatchable", function (e, isSelect) {
        e.preventDefault();
        if ($(this).attr("disabled") || ($(this).find("span.selected").length > 0)) {
            return;
        }
        var $productContainer = $(this).closest(".set-item");
        if (!$productContainer.length) {
            $productContainer = $(this).closest(".product-detail");
        }
        if ($productContainer.find(".attribute .size-value").length > 0 && $productContainer.find(".size-selection-error").length > 0) {
            $productContainer.find(".size-selection-error").addClass("d-none");
        }
        var isQuickView = $(this).parents("#quickViewModal").length > 0;
        var _url = appendParamsToUrl($(this).attr("data-url"), "isQuickView", isQuickView);

        if ($(".pdp-landingPage").length > 0) {
            _url = appendParamsToUrl(_url, "isLandingPDP", true);
        }

        if ($(".galleryView-pdp").length > 0) {
            _url = appendParamsToUrl(_url, "isGalleryViewPDP", true);
        }

        // var currentSelectedClass = $(this).attr("class");
        // if (!isSelect && currentSelectedClass) {
        // if (currentSelectedClass.indexOf("color-attribute") > -1) {
        // isSelect = true;
        // }
        // } */

        baseProduct.attributeSelect(_url, $productContainer, isSelect, $(this));
        setTimeout(function () {
            tooltip.getProductSpecificationToolTipData();
        }, 3000);
    });


    $(".js-attribute-hover").on("mouseover", ".swatchable", function (e) {
        e.preventDefault();
        var hoveredValue = $(this).find(".swatch-value").data("attr-value");
        $(".js-attribute-hover").find(".selected-variant-value").text(hoveredValue);
    });

    $(".js-attribute-hover").on("mouseleave", ".swatchable", function (e) {
        e.preventDefault();
        var selectedValue = $(this).closest(".js-attribute-hover").find(".swatch-value.selected").data("attr-value");
        $(".js-attribute-hover").find(".selected-variant-value").text(selectedValue);
    });

    $("body").on("product:afterAttributeSelect", function (e, response) {
        $("#productdescriptionpdp").addClass("collapsible-active");
        $("#productdescriptionpdp").find(".pdp-collapsible-header").attr("aria-expanded", "true");
        $("#productdescriptionpdp").find(".pdp-description-holder").css("display", "block");

        const product = response.data.product;
        gtmHelper.fireGtmEvent("PDP", product);

        if (product.variationAttributes) {
            updateAvailability(response.data, response.container);
        }

        if ($(".galleryview-primary-images").length > 0) {
            $(".galleryview-primary-images").html($(product.imagesHtml).html());
        } else {
            $(".primary-images").html($(product.imagesHtml).html());
        }
        carousel.initMainCarousel();
        carousel.initNavCarousel(true);
        zoom.initZoom();
    });

    $(document).on("click", ".galleryView-pdp .size-container .swatchable", function (e) {
        e.preventDefault();
        $(".selected-size-number").text("" + $(this).data("attr-id"));
    });
};

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = "";
    var availabilityMessages = response.product.availability.messages;
    var advancedMessages = response.product.availability.advancedMessages;
    if (!response.product.readyToOrder) {
        availabilityValue = "<li><div>" + response.resources.info_selectforstock + "</div></li>";
    } else if (response.product.isPreOrder != null && response.product.isPreOrder) {
        availabilityValue += "<li><div class=\"" + "\">" + "</div></li>";
    } else if (advancedMessages && advancedMessages.length > 0) {
        advancedMessages.forEach(function (message) {
            availabilityValue += "<li><div class=\"" + message.class + "\">" + message.message + "</div></li>";
        });
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += "<li><div>" + message + "</div></li>";
        });
    }

    $($productContainer).trigger("product:updateAvailability", {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}
