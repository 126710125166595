/**
 * Fires data layer event based on the event name
 * @param {string} eventName - event name
 * @param {Object} data - datalayer JSON
 */
function fireGtmEvent(eventName, data) {
    if (resources.prefs.isGTMEnabled == "true") {
        var dataLayerObj;
        switch (eventName) {
            case "HOME":
                window.dataLayer.push({ "ecomm_pagetype": "home" });
                if (resources.prefs.isBannerTrackingEnabled == "true") {
                    var imgIndex = 0;
                    var gaID = "Homepage-Desktop";
                    var isMobile = /Android|Mobile|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Silk|Opera Mini/i.test(navigator.userAgent) ? true : false;
                    if (isMobile) {
                        gaID = "Homepage-Mobile";
                    }
                    var view_promo = "view_promotion";
                    var click_promo = "select_promotion";
                    $(".main-hero-carousel").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category = "Hero";
                            var position = imgIndex + 1;
                            imgIndex++;
                            position = position.toString();
                            var gaObj = {
                                promotion_id: gaID,
                                promotion_name: category,
                                creative_name: this.alt || "",
                                creative_slot: position
                            };
                            addToDataLayer(gaObj, view_promo);
                            $(this).click(function () {
                                addToDataLayer(gaObj, click_promo);
                            });

                        }
                    });
                    imgIndex = 0;
                    $(".mobile-2r-1c").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category1 = "Para1";
                            var position1 = imgIndex + 1;
                            imgIndex++;
                            position1 = position1.toString();
                            var gaObj1 = {
                                promotion_id: gaID,
                                promotion_name: category1,
                                creative_name: this.alt || "",
                                creative_slot: position1
                            };
                            addToDataLayer(gaObj1, view_promo);
                            $(this).click(function () {
                                addToDataLayer(gaObj1, click_promo);
                            });
                        }
                    });
                    imgIndex = 0;
                    $(".mobile-3r-1c").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category2 = "Para2";
                            var position2 = imgIndex + 1;
                            imgIndex++;
                            position2 = position2.toString();
                            var gaObj2 = {
                                promotion_id: gaID,
                                promotion_name: category2,
                                creative_name: this.alt || "",
                                creative_slot: position2
                            };
                            addToDataLayer(gaObj2, view_promo);
                            $(this).click(function () {
                                addToDataLayer(gaObj2, click_promo);
                            });
                        }
                    });
                    imgIndex = 0;
                    $(".simple-1r-1c").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category3 = "Para3";
                            var position3 = imgIndex + 1;
                            imgIndex++;
                            position3 = position3.toString();
                            var gaObj3 = {
                                promotion_id: gaID,
                                promotion_name: category3,
                                creative_name: this.alt || "",
                                creative_slot: position3
                            };
                            addToDataLayer(gaObj3, view_promo);
                            $(this).click(function () {
                                addToDataLayer(gaObj3, click_promo);
                            });
                        }
                    });
                    imgIndex = 0;
                    $(".experience-commerce_layout-bannerGroup .bannergroup-wrap").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            var category4 = "Para4";
                            var position4 = imgIndex + 1;
                            imgIndex++;
                            position4 = position4.toString();
                            var gaObj4 = {
                                promotion_id: gaID,
                                promotion_name: category4,
                                creative_name: this.alt || "",
                                creative_slot: position4
                            };
                            addToDataLayer(gaObj4, view_promo);
                            $(this).click(function () {
                                addToDataLayer(gaObj4, click_promo);
                            });
                        }
                    });
                    $(".mobile-2r-2c").find("img").each(function () {
                        if ($(this).css("display") != "none") {
                            console.log("requset para 5");
                            var category5 = "Para5";
                            var position5 = imgIndex + 1;
                            imgIndex++;
                            position5 = position5.toString();
                            var gaObj5 = {
                                promotion_id: gaID,
                                promotion_name: category5,
                                creative_name: this.alt || "",
                                creative_slot: position5
                            };
                            addToDataLayer(gaObj5, view_promo);
                            $(this).click(function () {
                                addToDataLayer(gaObj5, click_promo);
                            });
                        }
                    });
                }

                break;
            case "CATEGORY":
                if ($(document).find("#dataLayerObject").length) {
                    dataLayerObj = $(document).find("#dataLayerObject").data("gtm");
                    if ($(".container .plp-header").find("img").length > 0) {
                        dataLayerObj.categoryimageURL = $($(".container .plp-header").find("img")[0]).data("src");
                    }
                    window.dataLayer.push(dataLayerObj);
                }
                break;
            case "CART":
                if ($(document).find("#dataLayerObject").length) {
                    dataLayerObj = $(document).find("#dataLayerObject").data("gtm");
                    window.dataLayer.push(dataLayerObj);
                }
                break;
            case "LIVETVPDP":
                window.dataLayer.push({
                    event: "view_item",
                    ecommerce: {
                        currency: "USD",
                        value: data.ecommerce.value,
                        items: [
                            {
                                item_id: data.ecommerce.items[0].item_id,
                                item_name: data.ecommerce.items[0].item_name,
                                affiliation: "LiveTv",
                                coupon: data.ecommerce.items[0].couponPromotion ? data.ecommerce.items[0].couponPromotion.id : "",
                                discount: data.ecommerce.items[0].couponPromotion ? data.ecommerce.items[0].couponPromotion.discount : 0,
                                index: 0,
                                item_brand: data.ecommerce.items[0].brand ? data.ecommerce.items[0].brand : "",
                                item_variant: data.ecommerce.items[0].productType ? data.ecommerce.items[0].productType : "",
                                item_category: data.ecommerce.items[0].primaryCategory ? data.ecommerce.items[0].primaryCategory : "",
                                item_category2: "",
                                item_category3: "",
                                item_category4: "",
                                item_category5: "",
                                item_list_id: "",
                                item_list_name: "",
                                price: data.ecommerce.value,
                                quantity: 1,
                            }
                        ],
                    },
                });
                break;
            case "ADD_TO_CART":
                if (resources.prefs.isGTMAddToCartEventEnabled == "true") {
                    if ($(document).find("#dataLayerObject").length) {
                        dataLayerObj = $(document).find("#dataLayerObject").data("gtm");
                        window.dataLayer.push({ "ecomm_prodid": [dataLayerObj["ecomm_prodid"]], "value": parseFloat(dataLayerObj["ecomm_totalvalue"]) });
                    }
                    window.dataLayer.push({"event": "addToCart"});
                }
                break;
            case "PLP_ADD_TO_CART":
                if (resources.prefs.isGTMAddToCartEventEnabled == "true") {
                    if (data) {
                        window.dataLayer.push({ "ecomm_prodid": [data.pid], "value": parseFloat(data.price) });
                    }
                    window.dataLayer.push({"event": "addToCart"});
                }
                break;
            case "REMOVE_FROM_CART":
                if (data) {
                    window.dataLayer.push(data);
                }
                window.dataLayer.push({"event": "removeFromCart"});
                break;
            case "ACTIVATION":
                if (resources.prefs.isGTMActivationEventEnabled == "true") {
                    window.dataLayer.push({"event": "activation"});
                }
                break;
            case "PURCHASE":
                if (resources.prefs.isGTMPurchaseEventEnabled == "true") {
                // dataLayer.push({"event":"fastbuy"});
                    if (data && data.isFirstOrder) {
                        window.dataLayer.push({"event": "activation"});
                        if ($(document).find("#dataLayerObject").length) {
                            dataLayerObj = $(document).find("#dataLayerObject").data("gtm");
                            window.dataLayer.push({
                                "ecomm_prodid": dataLayerObj["ecomm_prodid"],
                                "ecomm_pagetype": "purchase",
                                "new_customer"  : "true"
                            });
                        }
                    }
                }
                break;
            case "FASTBUY_PURCHASE":
                if (resources.prefs.isGTMPurchaseEventEnabled == "true") {
                    if (data && data.order) {
                        dataLayerObj = {};
                        var order = data.order;
                        var productCodes = [];
                        var products = [];
                        var productsPurchaseData = [];
                        var isLiveTvItem = false;
                        order.shipping.forEach(function (shippingModel) {
                            shippingModel.productLineItems.items.forEach(function (lineItem) {
                                isLiveTvItem = lineItem.liveTV !== undefined && lineItem.liveTV ? true : false;
                                productCodes.push(lineItem.id);
                                products.push({ category: "abc", name: lineItem.productName, quantity : lineItem.quantity, sku : lineItem.id, price: isLiveTvItem ? parseFloat(lineItem.priceTotal.price.replace(/\s|\$|,/g, ""))/lineItem.quantity : lineItem.price.sales.value, affiliation: isLiveTvItem ? "LiveTv" : "FPC"});
                                productsPurchaseData.push({ category: "abc", item_name: lineItem.productName, quantity : Number(lineItem.quantity), item_id : lineItem.id, price: isLiveTvItem ? parseFloat(lineItem.priceTotal.price.replace(/\s|\$|,/g, ""))/lineItem.quantity : Number(lineItem.price.sales.value), affiliation: isLiveTvItem ? "LiveTv" : "FPC"});
                            });
                        });
                        dataLayerObj["ecomm_prodid"] = productCodes;
                        dataLayerObj["ecomm_pagetype"] = "purchase";
                        dataLayerObj["new_customer"] = "false";
                        dataLayerObj["ecomm_totalvalue"] = order.totals.subTotal;
                        dataLayerObj["ecomm_totalvalue_price"] = Number(order.totals.subTotal.replace(/[$,]/g, ""));
                        dataLayerObj["customerEmail"] = order.orderEmail;
                        dataLayerObj["discounts"] = Number(order.totals.totalDiscounts.replace("$", ""));
                        dataLayerObj["shippingDate"] = "";
                        dataLayerObj["estDeliveryDate"] = "";
                        dataLayerObj["delivery_country"] = order.shipping[0].shippingAddress.countryCode;
                        dataLayerObj["hasPreorder"] = "N";
                        dataLayerObj["hasDigitalGoods"] = "N";
                        dataLayerObj["transactionId"] = order.orderNumber;
                        dataLayerObj["transactionAffiliation"] = "FPC";
                        dataLayerObj["transactionTotal"] = Number(order.priceTotal.replace(/[$,]/g, ""));
                        dataLayerObj["transactionTax"] = Number(order.totals.totalTax.replace("$", ""));
                        dataLayerObj["transactionShipping"] = Number(order.totals.totalShippingCost.replace("$", ""));
                        dataLayerObj["transactionProducts"] = products;
                        dataLayerObj["productsPurchase"] = productsPurchaseData;
                        dataLayerObj["customerId"] = order.customerNumber;
                        window.dataLayer.push(dataLayerObj);
                        if (order.isFirstOrder) {
                            window.dataLayer.push({"event": "activation"});
                        }
                    }
                    window.dataLayer.push({"event": "fastbuy"});
                }
                break;
            case "BID":
                if (resources.prefs.isGTMBidEventEnabled == "true") {
                    if (data) {
                        window.dataLayer.push({"product_sku": data.productCode});
                        if (data.maxbid) {
                            if (data.success) {
                                window.dataLayer.push({"event":"maxbid"});
                            } else {
                                window.dataLayer.push({"event":"maxbid-error"});
                            }
                        } else {
                            if (data.success) {
                                window.dataLayer.push({"event":"bidnow"});
                            } else {
                                window.dataLayer.push({"event":"bidnow-error"});
                            }
                        }
                    }
                }
                break;
            case "NewUserRegistartion":
                dataLayerObj = {};
                dataLayerObj["event"] = "NewUserRegistartion";
                if (data) {
                    dataLayerObj["customerId"] = data.customerNo;
                }
                window.dataLayer.push(dataLayerObj);
                break;
            case "PDP":
                window.dataLayer.push({
                    event: "view_item",
                    ecommerce: {
                        currency: "USD",
                        value: typeof data.price === "object" ? data.price.sales.value : data.price,
                        items: [
                            {
                                item_id: data.id,
                                item_name: data.productName,
                                affiliation: "FPC",
                                coupon: data.couponPromotion ? data.couponPromotion.id : "",
                                discount: data.couponPromotion ? data.couponPromotion.discount : 0,
                                index: 0,
                                item_brand: data.brand ? data.brand : "",
                                item_variant: data.productType,
                                item_category: data.primaryCategory,
                                item_category2: "",
                                item_category3: "",
                                item_category4: "",
                                item_category5: "",
                                item_list_id: "",
                                item_list_name: "",
                                price: typeof data.price === "object" ? data.price.sales.value : data.price,
                                quantity: 1,
                            }
                        ],
                    },
                });
                break;
            case "Error":
                window.dataLayer.push({
                    event: "error",
                    Err_cat: "",
                    err_subcat: "",
                    Err_msg: data.errorMessage,
                    Referrer: "",
                    issue_array: []
                });
                break;
            case "DISCOUNT_APPLIED":
                window.dataLayer.push({
                    event: "discount_applied",
                    method: data.isManuallyAdded ? "manual" : "auto",
                    coupon_code: data.couponCode ? data.couponCode : "",
                    coupon_discount_value: data.discountValue ? data.discountValue : ""
                });
                break;
            default :
                window.dataLayer.push({ "ecomm_pagetype": "home" });
        }
    }
}

function addToDataLayer(promotion, eventName) {
    window.dataLayer.push({
        event: eventName,
        ecommerce:promotion
    });
}

module.exports = {
    fireGtmEvent: fireGtmEvent
};
