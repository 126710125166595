module.exports = {
    appendToUrl: function (url, params) {
        var newUrl = url;
        newUrl += (newUrl.indexOf("?") !== -1 ? "&" : "?") + Object.keys(params).map(function (key) {
            return key + "=" + encodeURIComponent(params[key]);
        }).join("&");

        return newUrl;
    },

    getUrlParameter(url, param) {
        var pageURL = url.split("?")[1];
        if (pageURL) {
            if (pageURL.indexOf("#") !== -1) {
                pageURL = pageURL.split("#")[0];
            }
            var urlVariables = pageURL.split("&");
            var urlParameterName;

            for (var i = 0; i < urlVariables.length; i++) {
                urlParameterName = urlVariables[i].split("=");
                if (urlParameterName[0] === param) {
                    return typeof urlParameterName[1] == undefined ? "" : decodeURIComponent(urlParameterName[1]);
                }
            }
        }
        return "";
    },

    removeParamFromURL(url, name) {
        if (url.indexOf("?") === -1 || url.indexOf(name + "=") === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split("?")[0];
        var paramUrl = url.split("?")[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf("#") > -1) {
            hash = paramUrl.split("#")[1] || "";
            paramUrl = paramUrl.split("#")[0];
        }
        params = paramUrl.split("&");
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split("=")[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + "?" + newParams.join("&") + (hash ? "#" + hash : "");
    },

    parseHtml: function (html) {
        var $html = $("<div>").append($.parseHTML(html));

        var body = $html;

        return {
            body: body
        };
    },

    /**
    * Get host name from provided url
    */
    getHostName: function (url) {
        var host = null,
            urlString = url;

        if (typeof url !== "string" && "toString" in url && typeof url.toString === "function") {
            urlString = url.toString();
        }

        var protocolUrlParts = urlString.split("://");

        if (protocolUrlParts.length > 1) {
            var noProtocolUrl = protocolUrlParts[1];
            var urlParts = noProtocolUrl.split("/");

            if (urlParts) {
                host = urlParts[0];
            }
        }

        return host;
    },

    /**
     * Get cookie value by cookie name from browser
     * @param {string} cookieName - name of the cookie
     * @returns {string} cookie value of the found cookie name
     */
    getCookie: function (cookieName) {
        var name = cookieName + "=";
        var decodedCookie = document.cookie;
        var cookieArray = decodedCookie.split(";");
        for (var i = 0; i < cookieArray.length; i++) {
            var cookieItem = cookieArray[i];
            while (cookieItem.charAt(0) === " ") {
                cookieItem = cookieItem.substring(1);
            }
            if (cookieItem.indexOf(name) === 0) {
                return cookieItem.substring(name.length, cookieItem.length);
            }
        }
        return "";
    },

    /**
     * Set cookie value by cookie name from browser
     * @param {string} cookieName - name of the cookie
     * @returns {string} cookie value
     */
    setCookie: function (name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    },

    openModel: function (title, modelId, data) {
        var htmlString = "<!-- Modal -->" +
            "<div class=\"modal fade mt-5\" id="+modelId+" tabindex=\"-1\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
            "<!-- Modal content-->" +
            "<div class=\"modal-content\">" +
            "<div class=\"modal-header\">" +
            (title ? "<span class=\"header-title\">" + title + "</span>" : "") +
            "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
            "<span aria-hidden=\"true\"> &times; </span>" +
            "</button>" +
            "</div>" +
            (title ? "<div class=\"address-content\">" :
                "<div class=\"modal-body\">" +
            "<div class=\"clearfix row\">") +
            data +
            (title ? "</div>" : "") +
            "</div>" +
            "</div>" +
            "</div>" +
            "</div>";
        $("body").find("#"+modelId).remove();
        $("body").append(htmlString);

        $("#"+modelId).modal("show");
        $("body").trigger(modelId+":ready");
    },

    formatDate: function (date, format) {
        var d = date ? new Date(date) : new Date();
        var dateMap = {
            DD: this.addLeadingZero(d.getDate()),
            MM: this.addLeadingZero(d.getMonth() + 1),
            YYYY: d.getFullYear().toString(),
            YY: d.getFullYear().toString().substr(-2)
        };
        var formatter = format ? format : "DD-MM-YYYY";
        var delimiter = formatter.indexOf("/") !== -1 ? "/" : "-";
        var keys = format.split(delimiter);
        return dateMap[keys[0].toUpperCase()] + delimiter +
                dateMap[keys[1].toUpperCase()] + delimiter +
                dateMap[keys[2].toUpperCase()];
    },

    addLeadingZero: function (m) {
        var pref = m > 9 ? "" : "0";
        return pref + m;
    },

    isDataJson: function (data) {
        try {
            JSON.parse(data);
        } catch (e) {
            return false;
        }
        return true;
    },

    // downloads image with the given url and the name passed
    downloadImage: function (url, fileName) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement("a");
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        };
        xhr.send();
    },

    sendFBEvent: function (fbcUrl, data) {
        $.ajax({
            url: fbcUrl,
            type: "POST",
            data: data,
            dataType: "JSON",
            success: function () {
            },
            error: function (err) {
                console.log(err);
            }
        });
    }
};
