var cartMessage = require("./cartMessage");
var dyHelper = require("../dynamicyield/dy");
var gaHelper = require("../tracking/googleanalytics");
var gtmHelper = require("../gtm/gtm");
var csTracking = require("../tracking/commentSoldTracking");
var modalResponseHelper = require("./modalResponseHelper");
var peTracking = require("../tracking/pushEngageTrackingEvent");
var util = require("../util");

module.exports = {
    addToCart: function () {
        $(document).on("click", ".add-to-cart, .js-add-multiple-qty-btn", function () {
            var $this = $(this);

            if ($this.prop('disabled')) {
                return;
            }

            var $product = $this.closest(".product");
            var $productTile = $this.parents(".product-tile");
            var $text = $this.find('.text');
            var addToCartUrl = $this.data("url");
            var pid = $product.data("pid");
            var qty = 1;
            const fade = 200;

            if ($this.hasClass("js-add-multiple-qty-btn")) {
                pid = $this.closest(".buy-more-save-more-container .product-container").data("pid");
            }

            if (!pid) {
                pid = $(".product-id").text();
            }

            if (!addToCartUrl || !pid) {
                return;
            }

            $this.prop('disabled', true);
            $text.fadeOut(fade, function() {
                $text.text($this.attr('data-adding'));
            }).fadeIn(fade);

            var plpPrice = $productTile.find(".sales").find(".js-formatted-price").data("formatedprice");

            if (!plpPrice && $this.parents(".buy-more-save-more-container").length > 0) {
                plpPrice = $this.closest(".product-container").find(".js-formatted-price").html().trim().replace("$", "");
            }

            if (plpPrice === undefined) {
                plpPrice = $productTile.find(".js-formatted-price").html().trim().replace("$", "");
            }

            var dyForm = {
                pid: pid,
                quantity: qty,
                price: plpPrice
            };
            dyHelper.fireDYEvent("ADD_TO_CART", dyForm);

            var csTrackData = {
                sku: pid,
                price: plpPrice,
                currency: "USD",
                quantity: qty
            };

            if ($("#quickViewModal.modal.show").length > 0 && $("#quantity").length == 1)
                qty = parseInt($("#quantity").val(), 10);
            var form = {
                pid: pid,
                quantity: qty
            };
            var gaForm = {
                id: pid,
                quantity: qty,
                price: plpPrice,
                category: $product.data("pcategory"),
                name: $product.data("pname")
            };
            var prodDetObjForPE = $product.data("productdetails");
            if ((typeof prodDetObjForPE === "string" || prodDetObjForPE instanceof String) && prodDetObjForPE != "") {
                prodDetObjForPE = JSON.parse(prodDetObjForPE);
            }
            //alert expiry time current time + 30 days
            var currentdate = new Date();
            currentdate.setDate(currentdate.getDate() + 30);
            var expiryisodate = currentdate.toISOString();
            var pePDAObj = {
                productid: prodDetObjForPE ? prodDetObjForPE.id : "",
                link: prodDetObjForPE ? prodDetObjForPE.pdpurl : "",
                price: prodDetObjForPE ? prodDetObjForPE.listprice : "",
                alertprice: prodDetObjForPE ? prodDetObjForPE.salesprice : "",
                expiryisodate: expiryisodate,
                availability: "inStock",
                variantid: prodDetObjForPE ? prodDetObjForPE.id : "",
                title: prodDetObjForPE ? prodDetObjForPE.name : "",
                imageurl: $(".brand-logo").find(".content-asset img")[0] ? $(".brand-logo").find(".content-asset img")[0].currentSrc : "",
                bigimageurl: prodDetObjForPE ? prodDetObjForPE.imageurl : ""
            };
            $("body").trigger("product:beforeAddToCart", this);
            $this.trigger("updateAddToCartFormData", form);

            $.ajax({
                url: addToCartUrl,
                method: "POST",
                data: form,
                success: function success(data) {
                    if (data.error) {
                        gtmHelper.fireGtmEvent("Error", { errorMessage: data.message });
                    }

                    cartMessage.handlePostCartAdd(data);
                    $("body").trigger("product:afterAddToCart", data);
                    $("body").trigger("fpcProduct:afterAddToCart", data);
                    $(".minicart").trigger("count:update", data);

                    gaHelper.fireGAEvent("PLP_ADD_TO_CART", gaForm, "FPC");
                    gtmHelper.fireGtmEvent("PLP_ADD_TO_CART", dyForm);
                    csTracking.fireCSEvent("ADD_TO_CART", csTrackData);
                    peTracking.pushEngageEvent("ADD_TO_CART_CA", pePDAObj);
                    if (pePDAObj.link !== "") {
                        peTracking.pushEngageEvent("PRICE_DROP_ALERT", pePDAObj);
                    }
                    peTracking.pushEngageEvent("CATEGORY_ACTIVITY_EVENT");
                    var fbenabled= $("#facebookURLAddToCart").data("isenabled");
                    if (fbenabled) {
                        var getIPApiUrl = $("#getIPApiUrl").val();
                        var fbcUrl= $("#facebookURLAddToCart").val();
                        var fbp = util.getCookie("_fbp");
                        var fbc = util.getCookie("_fbc");
                        var fbcData = {
                            pid: pid,
                            event_source_url: window.location.href,
                            client_user_agent: window.navigator.userAgent,
                            currency: csTrackData.currency,
                            price: csTrackData.price,
                            fbp: fbp,
                            fbc: fbc
                        };
                        $.ajax({
                            url: getIPApiUrl,
                            method: "GET",
                            dataType: "json",
                            success: function (data) {
                                fbcData.client_ip_address = data.ip;
                                util.sendFBEvent(fbcUrl, fbcData);
                            },
                            error: function (error) {
                                console.log(error);
                            }
                        });
                    }

                    $text.fadeOut(fade, function() {
                        $text.text($this.attr('data-added'));
                    }).fadeIn(fade);

                    setTimeout(function() {
                        $text.fadeOut(fade, function() {
                            $text.text($this.attr('data-add'));
                        }).fadeIn(fade);
                    }, 5000);
                },
                error: function error(data) {
                    gtmHelper.fireGtmEvent("Error", {
                        errorMessage: data.responseJSON.errorMessage
                    });

                    var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                    if (modal) {
                        $(modal.modalId).modal("show");
                    }
                    $text.text($this.attr('data-add'));
                },
                complete: function () {
                    $this.prop('disabled', false);
                }
            });
        });
    }
};
